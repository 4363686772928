.step-0-element {
  height: 0;
  left: 50%;
  pointer-events: none;
  position: fixed;
  top: 50%;
  width: 0;
}

#joyride-step-step1 {
  bottom: 50px !important;
  left: 50px !important;
  top: auto !important;
  transform: none !important;

  @media all and (max-width: 1300px) {
    bottom: 20px !important;
    left: 20px !important;
  }
}

#joyride-step-step4, #joyride-step-step7 {
  @media all and (max-width: 1050px) {
    bottom: 20px !important;
    left: 20px !important;
    top: auto !important;
    transform: none !important;

    .joyride-step__arrow {
      display: none !important;
    }
  }
}

.joyride-step__header {
  display: none !important;
}

.joyride-step__container {
  border-radius: 20px;
  box-shadow: none !important;
  max-width: 346px !important;
  overflow: hidden;
  padding: 0 !important;

  .joyride-step__close {
    bottom: 16px;
    height: 20px !important;
    left: 50%;
    opacity: 0;
    overflow: hidden;
    right: auto !important;
    top: auto !important;
    transform: translateX(-50%);
    width: 100px !important;

    @media all and (max-width: 1300px) {
      bottom: 11px;
    }
  }
}

.joyride-step__body {
  font-family: var(--font-family);
  padding: 0 !important;
}

.tour-item {
  .top {
    margin-bottom: 32px;
    padding: 50px 0;

    @media all and (max-width: 1300px) {
      margin-bottom: 12px;
      padding: 30px 0;
    }

    &.pink {
      background: linear-gradient(251.51deg, #F996C6 -20.6%, #D83182 114.44%);
    }

    &.lilac {
      background: linear-gradient(254.46deg, #CFA2F9 -27.69%, #8945C9 112.02%);
    }

    &.mint {
      background: linear-gradient(247.87deg, #0CCFCF -21.57%, #037871 122.04%);
    }

    &.blue {
      background: linear-gradient(249.15deg, #59A0FC -16.12%, #0B5BC2 112.48%);
    }

    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
      max-width: 100%;

      @media all and (max-width: 1300px) {
        max-height: 80px;
      }
    }

    &.simple {
      margin-bottom: 0;

      img {
        width: 280px
      }
    }
  }

  .content {
    padding: 0 16px;
    text-align: center;

    @media all and (max-width: 1300px) {
      padding: 0 12px;
    }

    .title {
      color: var(--text-color);
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 18px;

      @media all and (max-width: 1300px) {
        font-size: 20px;
        margin-bottom: 8px;
      }
    }

    .description {
      color: var(--text-color-secondary);
      line-height: 1.5;
    }
  }
}

.joyride-step__footer {
  color: var(--third-color);
  font-weight: 500 !important;
  padding: 10px 16px 16px !important;

  @media all and (max-width: 1300px) {
    padding: 8px 16px !important
  }

  &:after {
    bottom: 20px;
    content: "Skip the tour";
    font-family: var(--font-family) !important;
    font-weight: 600;
    left: 50%;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%);

    @media all and (max-width: 1300px) {
      bottom: 11px;
    }
  }

  .joyride-step__prev-container {
    .pi {
      font-size: 26px;
      transform: translateY(2px);
    }
  }

  .joyride-step__counter-container {
    bottom: 190px;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translateX(-50%);

    @media all and (max-width: 1300px) {
      bottom: 140px;
    }

    .joyride-step__counter {
      color: #ffffff;
      font-family: var(--font-family) !important;
      font-size: 16px !important;
      font-weight: 500 !important;

      #joyride-step-step0 & {
        color: var(--third-color);
      }
    }

    #joyride-step-step0 & {
      bottom: 150px;

      @media all and (max-width: 1300px) {
        bottom: 120px;
      }
    }
  }

  .joyride-step__buttons-container {
    align-items: center;
    display: flex;
    height: 26px;
    justify-content: space-between;
    width: 100%;

    #joyride-step-step0 & {
      justify-content: flex-end;
    }
  }

  .joyride-step__button {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: var(--font-family) !important;
    font-weight: 600;
  }
}

.joyride-backdrop.backdrop-left {
  height: calc(100% + 16px);
  transform: translateX(-8px) translateY(-8px);
}

.joyride-backdrop.backdrop-right {
  height: calc(100% + 16px);
  transform: translateX(8px) translateY(-8px);
}

.joyride-backdrop.backdrop-top {
  transform: translateY(-8px);
}

.joyride-backdrop.backdrop-bottom {
  transform: translateY(8px);
}
